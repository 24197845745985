// theme icon wrapper settings
.theme-icon-wrapper {
    position: fixed;
    right: 40px;
    bottom: 30px;
    //cursor: pointer;
    background: var(--primary-bg);
    padding: 10px;
    border-radius: 50% 50% 0;
    box-shadow: var(--box-shadow);
    z-index: 999;
  }
  
  // theme icon
  .theme-icon {
    font-size: 35px;
    color: var(--color-tem);
    transition: all 0.5s linear;
    animation: theme 3s cubic-bezier(0.55, 0.085, 0.68, 0.53) 2s infinite
      alternate-reverse;
  }
  @keyframes theme {
    0% {
      transform: rotate(0deg) scale(0.7);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media screen and (max-width: 450px) {
    
    .theme-icon-wrapper {
      padding: 7px;
    }
    .theme-icon {
      font-size: 28px;
    }
  }