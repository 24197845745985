
// Variables
$dark-blue: #050627;
$light-blue: #050627;
$white: #fff;
$black: #050627;
$open-sans: 'Open Sans',
sans-serif;

// clear-fix mixin
@mixin cf {
  &::before,
  &::after {
    content: '';
    display: table;
  }
  &::after {
    clear: both;
  }
}


.container {
  margin: auto;
  width: 100%;
  padding: 3rem;
  margin-left: 13rem;
  

  .data {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(calc(100% + 1em));
    transition: transform 0.3s;
}
}

a {
  text-decoration: none;
}

h1 {
  font-family: $open-sans;
  font-weight: 300;
}

.row {
  margin: auto 0;
}

// Base styles
.card {
  float: left;
  padding: 1rem;
  min-width: 500px;
  .menu-content {
    @include cf;
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      display: inline-block;
    }
    a {
      color: $white;
    }
  }
  .wrapper {
    background-color: $white;
    min-height: 340px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 19px 38px rgba($black, 0.3), 0 15px 12px rgba($black, 0.2);
    border: 0.25px solid #D9D9D9;
    background-color: #050627;
    &:hover {
      .data {
        transform: translateY(0);
      }
    }
  }
  .data {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(calc(80px + 1em));
    transition: transform 0.3s;
    .content {
      padding: 2.5em;
      position: relative;
      z-index: 1;
      
    }
  }
  .type {
    font-size: 20px;
  }
  .title {
    margin-top: 10px;
    font-size: 16px;
  }
  .text {
    height: 70px;
    margin: 0;
  }
  input[type='checkbox'] {
    display: none;
  }
  input[type='checkbox']:checked + .menu-content {
    transform: translateY(-60px);
  }
}




.example-4 {
  .wrapper {
    //background: url('../../assets/images/img1.jpg') 30% 1% / cover no-repeat;
    background-color: #050627;
  }
  .date {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #050627;
    color: $white;
    padding: .75rem;
    span {
      display: block;
      text-align: center;
    }
    .day {
      font-weight: 700;
      font-size: 1rem;
      text-shadow: 2px 3px 2px rgba($black, 0.18);
    }
  }
  .content {
    background-color: #050627;
    color: #D9D9D9;
    box-shadow: 0 5px 30px 10px rgba($black, 0.3);
  }
  .title {
    a {
      color: #D9D9D9;
    }
  }
    .menu-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    li {
      text-align: center;
      width: 3rem;
      background-color: #050627;
      height: 3rem;
      position: relative;
      svg {
        color: #D9D9D9;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition:.8s ease all;


      }
    }
    a {
      font-size: 24px;
      color:#D9D9D9;
    }
  }
}


// alternate card style

// Base styles
.alternate-card {
  float: left;
  padding: 1rem;
  min-width: 500px;
  .menu-content {
    @include cf;
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      display: inline-block;
    }
    a {
      color: $white;
    }
  }
  .wrapper {
    background-color: $white;
    min-height: 540px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 19px 38px rgba($black, 0.3), 0 15px 12px rgba($black, 0.2);
    &:hover {
      .data {
        transform: translateY(0);
      }
    }
  }
  .data {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(calc(100% + 1em));
    transition: transform 0.3s;
    .content {
      padding: 1.5em;
      position: relative;
      z-index: 1;
    }
  }
  .type {
    font-size: 12px;
  }
  .title {
    margin-top: 10px;
  }
  .text {
    height: 325px;
    margin: 0;
  }
  input[type='checkbox'] {
    display: none;
  }
  input[type='checkbox']:checked + .menu-content {
    transform: translateY(-60px);
  }
}

// Media queries
@media screen and (max-width: 1024px) {
  .card {
    min-width: 300px;
  }
}

@media screen and (max-width: 768px) {
  .card {
    min-width: 100%;
  }

  .container {
    margin-left: 0;
  }
}

@media screen and (max-width: 480px) {
  .card {
    min-width: 100%;
  }
}







