.aside {
    width: 220px;
    height: 100%;
    position: fixed;
    //background: var(--sideBar-bg);
    background: #050627;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2.3px);
    -webkit-backdrop-filter: blur(2.3px);
    border: 0.2px solid rgba(156, 167, 198, 0.47);
    top: 0;
    left: 0;
    z-index: 3;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: translate(0);
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 180px;
      top: 0;
      left: 0;
      //background-color: var(--typical-color);
      
    }
  }
  // show sidebar when you click on the icon menu
  .show-asideBar {
    transform: translate(-100%);
  }
  
  // logo section in the sidebar
  .logo-section {
    background-color: #050627;
    color: #050627;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1rem;
    position: relative;
    font-weight: 20rem;
    margin-left: -2rem;
    span {
      color: #fff;
      font-size: 10rem; /* Adjust the font size as desired */
    }
    svg.logo-container {
      width: 200px; /* Adjust the width as desired */
      height: 200px; /* Adjust the height as desired */
    }
  
    .ell, #ai {
      fill: none;
    }
    
  }
  .sidebar__logo {
    background-color: #050627;
    width: 100%;
    height: 100%;
    fill: #cc6c06;
   
  }

  
   
   
  // the classname that wraps all the element in the sidebar
  .aside-wrapper {
    width: 80%;
    min-height: 90vh;
    overflow-y: auto;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  // toggle btn
  .menu-icon,
  .close-btn {
    position: fixed;
    right: 40px;
    top: 40px;
    color: var(--primary-color);
    font-size: 1.8rem;
    cursor: pointer;
    display: none;
    transition: all 0.2s linear;
    z-index: 2;
    
  }
  .menu-icon:hover {
    transform: translate(5px);
  }
  .close-btn {
    z-index: 999;
    position: absolute;
  }
  .close-btn:hover {
    transform: rotate(360deg);
  }
  
  // sidebar list element starts here
  
  ul.side-link li {
    line-height: 2.8;
    position: relative;
    padding-left: 20px;
    font-size: 1.2rem;
  }
  .nav__links {
    display: block;
    transition: all 250ms linear;
  }
  .active-links {
    color: var(--color-tem);
  }
  
  ul.side-link li .link-icon {
    font-size: 15px;
    margin-right: 15px;
    color: var(--color-tem);
  }
  
  // social media icons
  .social-icon {
    right: 100px;
    bottom: 0;
    font-size: 2rem;
    padding-left: 2.2rem;
  }
  .social-icon .icon {
    //font-size: 15px;
    margin-right: 15px;
    font-size: 2rem;
    transition: 0.2s linear;
    color: var(--color-tem);
    &:hover {
      color: var(--anchor-color);
    }
  }

  

  
 
     
     

   
  
  
  
  
  // for bigger screens
  
  @media screen and (max-width: 1200px) {
    .aside {
      width: 100%;
      height: 0;
      overflow: hidden;
      transition: all 350ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    .open-sidebar {
      height: 100%;
      overflow: visible;
    }
    .menu-icon,
    .close-btn {
      display: block;
    }
    .social-icon {
      text-align: center;
    }
  }
  
  // small devices
  @media screen and (max-width: 1024px) {
    .aside-wrapper {
      text-align: center;
    }
    ul.side-link li {
      line-height: 2.8;
      font-weight: bold;
    }
    ul.side-link li .link-icon {
      display: none;
    }
  
    // social media icons
    .social-icon {
      text-align: center;
    }
    .social-icon .icon {
      font-size: 25px;
      &:hover {
        animation: rubberBand 1s;
    }
  }

  




}

  