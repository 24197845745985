.section-about {
    position: relative;
    margin-bottom: 100px;
    
  }
  .section-about-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 0px;
  }
  .section-about-description {
    padding-top: 50px;
    margin-left: 5rem;
    
    display: grid;
    grid-template-columns: 1fr;
  }
  .section-about-description h2 {
    display: block;
    font-size: 2,5rem;
    margin-bottom: 25px;
    .text-animate-hover {
      &:hover {
        color: var(--color-tem);
      }
    }
  }
  .progress__bar h2 {
    font-size: 1.6rem;
  }
  .section-about-description p {
    font-size: 24px; 
    
  }
  
  
  .submit-button {
    margin-top: 10px;
    background: transparent;
    border: none;
    outline: none;
    color: #8a8484;
    cursor: pointer;
    font-size: 1rem;
  }
  
  
  @media screen and (min-width: 2000px) {
    .section-about-description {
      grid-template-columns: 1fr 1fr;
      gap: 2rem;
    }
    .section-about-description h2 {
      font-size: 7rem;
    }
    .about-description-skills {
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 2rem;
    }
  }
  
  @media screen and (max-width: 1900px) {
    .section-about-description {
      grid-template-columns: 2fr 1fr;
      gap: 2rem;
    }
    .section-about-description h2 {
      font-size: 3.5rem;
    }
    .about-description-skills {
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 2rem;
    }
  }
  
  @media screen and (max-width: 1024px) {
    .section-about-description {
      padding-top: 0;
    }
    .about-description-skills {
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 2rem;
    }
    .section-about-description h2 {
      font-size: 2.5rem;
    }
    .section-about-description {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media screen and (max-width: 980px) {
    .about-description-skills {
      grid-template-columns: repeat(4, 1fr);
    }
    .section-about-description {
      grid-template-columns: 1fr;
    }
  }
  
  @media screen and (max-width: 768px) {
    .section-about-description h2 {
      font-size: 2.3rem;
    }
    .about-description-skills {
      grid-template-columns: repeat(3, 1fr);
    }
    .section-about-description {
      grid-template-columns: 1fr;
    }
  }
  
  @media screen and (max-width: 450px) {
    .section-about-description {
      grid-template-columns: 1fr;
    }
    .section-about-description h2 {
      font-size: 2rem;
    }
    .about-description-skills {
      grid-template-columns: repeat(1, 1fr);
    }
    .section-about-description p {
      font-size: 0.975rem;
    }
  }